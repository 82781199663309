/*
 * @Author: lee
 * @Date: 2022-11-16 12:09:07
 * @LastEditTime: 2022-11-16 14:02:31
 */
import { createTheme } from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";


const theme = createTheme({
    palette: {
      primary: blue,
    },
    overrides: {
      MuiButton: {
        label: {
          color: "#f1f1f1",
        },
      }
    }
});
  
export default theme;
