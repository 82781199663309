/*
 * @Author: lee
 * @Date: 2022-11-09 10:20:57
 * @LastEditTime: 2022-11-16 11:47:46
 */
import React, { Component } from "react";

import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";


const styles = {
  formControl: {
    paddingTop: 24,
  },
}


class Form extends Component {
  render() {
    return(
      <form onSubmit={this.props.onSubmit}>
        {this.props.children}

        <Grid container justifyContent="flex-end" className={this.props.classes.formControl}>
          {this.props.extraButtons}
          {this.props.submitLabel && <Button variant="contained" style={{backgroundColor: "#000000"}} type="submit" disabled={this.props.disabled}>{this.props.submitLabel}</Button>}
        </Grid>
      </form>
    );
  }
}

export default withStyles(styles)(Form);
