import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

import queryString from "query-string";

import Form from "../../components/Form";
import FormComponent from "../../classes/FormComponent";
import SessionStore from "../../stores/SessionStore";
import InternalStore from "../../stores/InternalStore";
import theme from "../../theme";


const styles = {
  tips: {
    background: 'rgba(0,0,0,.5)',
    color: '#FFEEB2',
    position: 'absolute',
    width: '370px',
    bottom: '19%',
    left: "60%",
    position: 'absolute',
    fontSize: '1.1rem'
  },
  textField: {
    width: "100%",
  },
  title:{
    color: "white"
  },
  link: {
    "& a": {
      color: theme.palette.primary.main,
      textDecoration: "none",
    },
  },
  loginBox: {
    position: 'absolute', 
    left: '50%', top: '50%', 
    transform: 'translate(-50%, -50%)'
  },
  bg: {
    backgroundImage: `url(/background.png)`,
    position: "absolute",
    left: "0",
    top: "0",
    width: "100%",
    height: "100%",
    backgroundSize: "cover"
  },
  cssLabel: {
    color: "white"
  }
};


class LoginForm extends FormComponent {
  render() {
    if (this.state.object === undefined) {
      return null;
    }
  
    return(
      <Form
        submitLabel={this.props.submitLabel}
        onSubmit={this.onSubmit}
      >
        <TextField
          id="email"
          label="Username / email"
          margin="normal"
          value={this.state.object.email || ""}
          onChange={this.onChange}
          fullWidth
	  // InputProps={{
	  //  classes: {
	  //	   input: classes.input
	  //	}
	  //}}
	  InputProps={{ style: { color: "white" } }}
	  InputLabelProps = {{ style: { color: "white" } }}
          required
        />
        <TextField
          id="password"
          label="Password"
          type="password"
          margin="normal"
          value={this.state.object.password || ""}
          onChange={this.onChange}
          fullWidth
         // InputProps={{
	 //   classes: {
	 //	   input: classes.input
	 //	}
	 // }}
	  inputProps={{ style: { color: "white" } }}
	  InputLabelProps = {{ style: { color: "white" } }}
          required
        />
      </Form>
    );
  }
}

class OpenIDConnectLogin extends Component {
  render() {
    return(
      <div>
        <a href={this.props.loginUrl}><Button variant="outlined">{this.props.loginLabel}</Button></a>
      </div>
    );
  }
}


class Login extends Component {
  constructor() {
    super();

    this.state = {
      loaded: false,
      registration: "",
      oidcEnabled: false,
      oidcLoginlabel: "",
      oidcLoginUrl: "",
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    SessionStore.logout(true, () => {});

    InternalStore.settings(resp => {
      this.setState({
        loaded: true,
        registration: resp.branding.registration,
        oidcEnabled: resp.openidConnect.enabled,
        oidcLoginUrl: resp.openidConnect.loginURL,
        oidcLoginLabel: resp.openidConnect.loginLabel,
      });
    });

    // callback from openid provider
    if (this.props.location.search !== "") {
      let query = queryString.parse(this.props.location.search);
  
      SessionStore.openidConnectLogin(query.code, query.state, () => {
        this.props.history.push("/");
      });
    }
  }

  onSubmit(login) {
    SessionStore.login(login, () => {
      this.props.history.push("/");
    });
  }

  render() {
    if (!this.state.loaded) {
      return null;
    }

    return(
      <div className={this.props.classes.bg}>
        <Card className={this.props.classes.tips}>
          <CardContent>
             <i style={{color: 'red',}}>*</i> Please contact Neuron administrator to request and obtain an account to login to Neuron LoRaWAN Server
          </CardContent>
        </Card>
        <div className={this.props.classes.loginBox}>
          <Card style= {{background: "rgba(33,41,77,.5)"}}>
            <CardHeader className={this.props.classes.title}
              title="Neuron LoRaWAN server"
              />
            <CardContent>
              {!this.state.oidcEnabled && <LoginForm
                submitLabel="Login"
                onSubmit={this.onSubmit}
                />}
              {this.state.oidcEnabled && <OpenIDConnectLogin
                loginUrl={this.state.oidcLoginUrl}
                loginLabel={this.state.oidcLoginLabel}
                />}
            </CardContent>
            {this.state.registration !== "" && <CardContent>
              <Typography className={this.props.classes.link} dangerouslySetInnerHTML={{__html: this.state.registration}}></Typography>
            </CardContent>}
          </Card>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(Login));
